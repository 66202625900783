<mat-nav-list
  class="app__nav-list thin-echo-scrollbar-hidden"
  [ngClass]="{ 'settings-opened': settingsOpened }"
>
  <div
    class="top-group"
    [ngClass]="{ compacted: isCompacted, 'settings-opened': settingsOpened }"
  >
    <ng-select
      [items]="careHomes"
      [clearable]="false"
      dropdownPosition="auto"
      bindValue="careHomeId"
      bindLabel="careHomeFullName"
      [(ngModel)]="defaultCareHomeId"
      (change)="onChangeCareHome($event)"
      placeholder="ALL CARE HOMES"
      (click)="toggleSidebar(isCompacted)"
      [virtualScroll]="true"
      [ngClass]="{ compacted: isCompacted }"
    >
      <ng-template ng-option-tmp let-item="item">
        <div>
          <span>{{ item.careHomeFullName }}</span>
        </div>
      </ng-template>
    </ng-select>
    <a class="search-field" [ngClass]="{ compacted: isCompacted }">
      <div class="search">
        <nb-icon
          icon="search-outline"
          (click)="toggleSidebar(isCompacted)"
        ></nb-icon>
        <input
          id="search-in-menu"
          placeholder="Search Nav Menu..."
          nbInput
          [(ngModel)]="inputValue"
          #input
          class="search-input"
          (input)="onInput($event.target.value)"
        />
      </div>
    </a>
    <div
      id="app-menu-scroll-container"
      class="menu-items thin-echo-scrollbar-2"
    >
      <div
        [routerLink]="'/inbox/categories/toRead/toRead'"
        class="side-menu-item"
        [ngClass]="{ compacted: isCompacted }"
      >
        <nb-icon icon="email-outline"></nb-icon>
        <div
          class="notification-circle"
          *ngIf="this.notifications.messages > 0"
          [innerText]="notifications.messages"
        ></div>
        <div class="email-outline-icon-text">Inbox</div>
      </div>

      <side-menu-options-accordion
        [isCompacted]="isCompacted"
      ></side-menu-options-accordion>

      <side-menu-accordion
        [menuItems]="menuItems"
        [toggleSidebar]="toggleSidebar"
        [openedIndex]="openedIndex"
        [isCompacted]="isCompacted"
        [openedUrl]="openedUrl"
        [openedSubmenuIndex]="openedSubmenuIndex"
      ></side-menu-accordion>
    </div>
  </div>
  <div class="bottom-group" [ngClass]="{ 'settings-opened': settingsOpened }">
    <div class="divider" *ngIf="!settingsOpened"></div>
    <nb-user
      class="user"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      [name]="user?.name"
      [picture]="user?.picture"
      (click)="isOpen = !isOpen"
      [title]="isUserAdmin ? 'Administrator' : 'User'"
      [onlyPicture]="isCompacted"
      [size]="'medium'"
    ></nb-user>

    <side-menu-accordion
      *ngIf="isUserAdmin"
      [menuItems]="settingsMenuItems"
      [toggleSidebar]="toggleSidebar"
      [openedIndex]="openedSubmenuIndex"
      [isCompacted]="isCompacted"
      [openedUrl]="openedUrl"
      [openedSubmenuIndex]="openedSettingsSubmenuIndex"
      [panel_type]="'settings'"
      (menuClick)="openSettingsMenu()"
    ></side-menu-accordion>
  </div>
</mat-nav-list>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div class="main-menu-selector-list">
    <ng-container *ngIf="isCompacted">
      <div class="user-info">
        <div class="user-name">{{ user?.name }}</div>
        <div class="user-title">
          {{ isUserAdmin ? "Administrator" : "User" }}
        </div>
      </div>
      <div class="divider"></div>
    </ng-container>
    <ng-container *ngFor="let item of userMenu">
      <div
        (click)="onMenuItemClick(item.action)"
        class="main-menu-selector-list-item"
      >
        {{ item.title }}
      </div>
    </ng-container>
  </div>
</ng-template>
