<div
  *ngIf="shift.employeeId && shift.shiftScheduleDuration !== 0"
  draggable="true"
  (onDragStart)="dragStarted()"
  (onDragEnd)="dragEnded()"
  class="rota-summary-table__person-wrapper"
  [style.width.px]="shiftWidth"
  [style.marginLeft.px]="shiftOffsetLeft"
  cdkOverlayOrigin
  #buttonsOrigin="cdkOverlayOrigin"
>
  <div
    [nbTooltip]="getShiftTooltip(shift)"
    nbTooltipPlacement="bottom"
    class="rota-summary-table__person shift editable"
    [ngClass]="{
      'rota-summary-table__person--confirmed':
        isConfirmed(shift) && type !== 'Schedule',
      'rota-summary-table__person--differentHours':
        isDifferentThanScheduled(shift) && type !== 'Schedule',
      'rota-summary-table__person--selected': isSelected && !isInResize,
      'rota-summary-table__person--resize': isInResize,
      'rota-summary-table__person--out-of-work': isOutOfWork(shift)
    }"
    (click)="selectForResizing(); $event.stopPropagation()"
    (dblclick)="editShift(); $event.stopPropagation(); $event.preventDefault()"
    #shifts
  >
    <nb-checkbox
      *ngIf="(isSelected || ctrlPressed) && !isOutOfWork(shift)"
      class="rota-summary-table__person__checkbox"
      [checked]="isSelected"
      (checkedChange)="toggleShiftSelection($event)"
      (click)="$event.stopPropagation()"
    ></nb-checkbox>
    <div class="info">
      <img
        [src]="shift.employeeAvatarUid | avatar | async"
        class="avatar"
        [alt]="shift.employeeFullName"
        (click)="
          toggleOverlayForEmployee(shift.employeeId, shift.employeeFullName)
        "
        [nbPopover]="popover"
        nbPopoverTrigger="hover"
        nbPopoverPlacement="right"
      />
      <div class="name">
        <span class="second-info">{{ getHoursForDisplay(shift) }}</span>
        <span class="first-name">{{ shift.employeeFullName }}</span>
      </div>
    </div>
    <div class="edit-icon" *ngIf="!isOutOfWork(shift)" (click)="editShift()">
      <nb-icon icon="edit-outline" class="icon"></nb-icon>
    </div>

    <div
      class="slider left-slider"
      *ngIf="isInResize"
      (mousedown)="startResize($event, 'left'); $event.preventDefault()"
    >
      <nb-icon icon="chevron-left-outline"></nb-icon>
    </div>
    <div
      class="slider right-slider"
      *ngIf="isInResize"
      (mousedown)="startResize($event, 'right'); $event.preventDefault()"
    >
      <nb-icon icon="chevron-right-outline"></nb-icon>
    </div>
    <div
      class="slider top-slider"
      *ngIf="isInResize"
      (mousedown)="startResize($event, 'move'); $event.preventDefault()"
    >
      <nb-icon icon="chevron-left-outline"></nb-icon>
      <nb-icon icon="chevron-right-outline"></nb-icon>
    </div>
  </div>
</div>

<ng-template #popover>
  <div class="open-employee" (click)="openEmployeeProfile()">
    Open Employee360
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="buttonsOrigin"
  [cdkConnectedOverlayOpen]="buttonsOpened"
  [cdkConnectedOverlayWidth]="235"
>
  <div class="floating-buttons">
    <button
      class="btn btn-success"
      (click)="
        onAcceptSelected(); $event.stopPropagation()
      "
    >
      <nb-icon icon="checkmark-outline" class="mr-2"></nb-icon> Accept
    </button>
    <button
      class="btn btn-danger"
      (click)="onDenySelected(); $event.stopPropagation()"
    >
      <i class="nb-close mr-2" aria-hidden="true"></i> Deny
    </button>
    <button
      class="btn btn-info"
      (click)="onClearSelected(); $event.stopPropagation()"
    >
      <nb-icon icon="backspace-outline" class="mr-2"></nb-icon> Clear
    </button>
  </div>
</ng-template>
