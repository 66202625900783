import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { IShift } from "../rota-summary-table.model";
import { ScheduleDayStatus } from "../../schedule-day-status.enum";
import { NgDragDropModule } from "ng-drag-drop";
import { NbTooltipModule } from "@nebular/theme";
import { ThemeModule } from "../../../../../@theme/theme.module";
import { CdkOverlayOrigin, OverlayModule } from "@angular/cdk/overlay";
import { RotaSummaryService } from "../services/rota-summary.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "rota-summary-table-shift",
  standalone: true,
  imports: [
    CommonModule,
    NgDragDropModule,
    NbTooltipModule,
    ThemeModule,
    OverlayModule,
  ],
  templateUrl: "./rota-summary-table-shift.component.html",
  styleUrls: [
    "../rota-summary-table.component.scss",
    "./rota-summary-table-shift.component.scss",
  ],
})
export class RotaSummaryTableShiftComponent implements OnInit, OnDestroy {
  @ViewChild(CdkOverlayOrigin, { static: false })
  buttonsOrigin: CdkOverlayOrigin;

  @Input() shift: IShift;
  @Input() shiftWidth: number = 0;
  @Input() shiftOffsetLeft: number = 0;
  @Input() type: "Schedule" | "Confirmed" | "Blend" = "Blend";
  @Input() isSelected: boolean = false;
  @Input() isInResize: boolean = false;
  @Input() ctrlPressed: boolean = false;

  @Output() public openEmployee360 = new EventEmitter<{
    elementId: number;
    elementFullName: string;
  }>();
  @Output() public resizeStart = new EventEmitter<{
    event: MouseEvent;
    shift: IShift;
    direction: "left" | "right" | "move";
  }>();
  @Output() public shiftEdit = new EventEmitter<IShift>();
  @Output() public shiftSelected = new EventEmitter<{
    shift: IShift;
    checked: boolean;
  }>();
  @Output() public selectedForResizing = new EventEmitter<IShift>();
  @Output() public dragStart = new EventEmitter<IShift>();
  @Output() public dragEnd = new EventEmitter<IShift>();

  @Output() public acceptSelected = new EventEmitter<void>();
  @Output() public denySelected = new EventEmitter<void>();
  @Output() public clearSelected = new EventEmitter<void>();

  public overlayEmployeeId: number = -1;
  public overlayEmployeeFullName: string = "";
  public buttonsOpened = false;

  constructor(private rotaSummaryService: RotaSummaryService) {}

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.rotaSummaryService.buttonsOpened
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (this.shift.rotaUid !== value) {
          this.buttonsOpened = false;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getShiftTooltip(shift: IShift) {
    let from = shift.scheduleFrom || shift.confirmedFrom;
    let to = shift.scheduleTo || shift.confirmedTo;
    let hrs = shift.scheduledHrs || shift.confirmedHrs;

    if (
      this.type !== "Schedule" &&
      (shift.statusId === ScheduleDayStatus.CONFIRMED ||
        shift.statusId === ScheduleDayStatus.CONFIRMED_OTHERS)
    ) {
      from = shift.confirmedFrom;
      to = shift.confirmedTo;
      hrs = shift.confirmedHrs;
    }

    return `${shift.employeeFullName} ${from} - ${to} (${hrs}h)`;
  }

  isConfirmed(item: IShift): boolean {
    return item.statusId === ScheduleDayStatus.CONFIRMED;
  }

  isDifferentThanScheduled(item: IShift): boolean {
    return item.statusId === ScheduleDayStatus.CONFIRMED_OTHERS;
  }

  isOutOfWork(item) {
    return (
      item.statusId === ScheduleDayStatus.SICK ||
      item.statusId === ScheduleDayStatus.AL ||
      item.statusId === ScheduleDayStatus.TRAINING_SICK ||
      item.statusId === ScheduleDayStatus.TRAINING_AL
    );
  }

  public toggleOverlayForEmployee(
    employeeId: number,
    employeeFullName: string,
  ) {
    this.overlayEmployeeId = employeeId;
    this.overlayEmployeeFullName = employeeFullName;
  }

  getHoursForDisplay(item: IShift): string {
    let result = `${item.scheduleFrom} - ${item.scheduleTo}`;

    if (this.type !== "Schedule") {
      if (
        item.statusId === ScheduleDayStatus.CONFIRMED ||
        item.statusId === ScheduleDayStatus.CONFIRMED_OTHERS
      ) {
        result = `${item.confirmedFrom} - ${item.confirmedTo}`;
      }
    }
    if (item.statusId === undefined) {
      result = `${
        this.type === "Schedule"
          ? item.scheduleFrom
          : item.confirmedFrom || item.scheduleFrom
      } - ${
        this.type === "Schedule"
          ? item.scheduleTo
          : item.confirmedTo || item.scheduleTo
      }`;
    }
    if (result.includes("null")) {
      result = "";
    }
    return result;
  }

  public openEmployeeProfile() {
    this.openEmployee360.next({
      elementId: this.overlayEmployeeId,
      elementFullName: this.overlayEmployeeFullName,
    });
  }

  public startResize(event: MouseEvent, direction: "left" | "right" | "move") {
    this.resizeStart.next({
      event: event,
      shift: this.shift,
      direction: direction,
    });
  }

  public editShift() {
    this.shiftEdit.next(this.shift);
  }

  public toggleShiftSelection(checked: boolean) {
    this.toggleButtons();
    this.shiftSelected.next({ shift: this.shift, checked: checked });
  }

  public selectForResizing() {
    this.toggleButtons();
    this.selectedForResizing.next(this.shift);
  }

  public dragStarted() {
    this.dragStart.next(this.shift);
  }

  public dragEnded() {
    this.dragEnd.next(this.shift);
  }

  public toggleButtons() {
    this.buttonsOpened = !this.buttonsOpened;
    this.rotaSummaryService.buttonsOpened.next(this.buttonsOpened ? this.shift.rotaUid : "");
  }

  public onAcceptSelected() {
    this.acceptSelected.next();
    this.toggleButtons();
  }
  public onDenySelected() {
    this.denySelected.next();
    this.toggleButtons();
  }
  public onClearSelected() {
    this.clearSelected.next();
    this.toggleButtons();
  }
}
