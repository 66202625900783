<div class="rota-summary-table">
  <div class="rota-summary-table__actions">
    <div class="show-options">
      <button
        class="btn btn-info option-button"
        (click)="showOptionsTooltip = !showOptionsTooltip"
      >
        <nb-icon icon="more-vertical-outline" class="mr-2"></nb-icon> Options
      </button>
      <div *ngIf="showOptionsTooltip" class="popup-for-options">
        <ul class="options-tooltip">
          <li class="subheader">
            <strong>{{ getTypeName() }} </strong>
          </li>
          <!-- <li class="with-icon">
                  <i class="nb-checkmark" aria-hidden="true"></i> Add shift
                </li> -->
          <li class="subheader"><strong>Mode</strong></li>
          <li
            class="with-icon"
            [ngClass]="{ green: type === 'Confirmed' }"
            (click)="changeType('Confirmed')"
          >
            <nb-icon class="icon" icon="checkmark-circle-2-outline"></nb-icon>
            Confirm mode
          </li>
          <li
            class="with-icon"
            [ngClass]="{ green: type === 'Blend' }"
            (click)="changeType('Blend')"
          >
            <nb-icon class="icon" icon="shuffle-outline"></nb-icon> Default mode
          </li>
          <li
            class="with-icon"
            [ngClass]="{ green: type === 'Schedule' }"
            (click)="changeType('Schedule')"
          >
            <nb-icon class="icon" icon="list-outline"></nb-icon> Schedule mode
          </li>
          <li class="subheader"><strong>Mode</strong></li>
          <li class="with-icon">
            <nb-icon class="icon" icon="list-outline"></nb-icon> Show list
          </li>
          <li class="with-icon" (click)="addShift()">
            <img src="/assets/images/add_new.svg" class="icon" alt="" /> Add new
            shift
          </li>
        </ul>
      </div>
    </div>

    <nb-icon
      icon="close-outline"
      class="close-icon"
      (click)="close()"
    ></nb-icon>
  </div>
  <div class="rota-summary-table__content">
    <div class="sticky">
      <div class="rota-summary-table__row rota-summary-table__row--wider">
        <div
          class="rota-summary-table__column rota-summary-table__heading rota-summary-table__green"
        >
          <!-- {{roleName }} <span class="date">{{ day }} {{ date }}</span> -->
          <ng-select
            [items]="roles"
            bindLabel="roleName"
            [multiple]="false"
            [clearable]="false"
            dropdownPosition="auto"
            bindValue="id"
            placeholder="Select role"
            class="role-select"
            [(ngModel)]="roleId"
            (ngModelChange)="changeRole($event)"
          >
          </ng-select>
        </div>
        <div
          class="rota-summary-table__column rota-summary-table__green rota-summary-table__heading rota-summary-table__heading--wide"
        >
          <nb-icon
            icon="arrow-ios-back-outline"
            class="day-icon"
            (click)="changeDay('minus')"
          ></nb-icon>
          <span class="date">{{ day }} {{ date }}</span>
          <nb-icon
            icon="arrow-ios-forward-outline"
            class="day-icon"
            (click)="changeDay('plus')"
          ></nb-icon>
        </div>
        <!-- <div class="rota-summary-table__column rota-summary-table__heading">AM: {{result?.amShift }}</div>
                <div class="rota-summary-table__column rota-summary-table__heading">PM: {{result?.pmShift }}</div>
                <div class="rota-summary-table__column rota-summary-table__heading">Night: {{result?.nightShift }}</div> -->
      </div>
      <!-- <div class="rota-summary-table__row">
                <div class="rota-summary-table__column rota-summary-table__heading">Budget</div>
                <div class="rota-summary-table__column">{{ result?.amNeeds || '-' }}</div>
                <div class="rota-summary-table__column">{{ result?.pmNeeds || '-' }}</div>
                <div class="rota-summary-table__column">{{ result?.nightNeeds || '-' }}</div>
            </div> -->
      <div class="rota-summary-table__row">
        <div class="rota-summary-table__column rota-summary-table__heading">
          Performance vs. budget
        </div>
        <div
          class="rota-summary-table__column rota-summary-table__column--with-label"
          [ngClass]="{
            over:
              getStaffLevelByType('am') &&
              !getStaffLevelByType('am').includes('-'),
            under:
              getStaffLevelByType('am') &&
              getStaffLevelByType('am').includes('-')
          }"
        >
          <div class="shift-type-label">AM</div>
          <div class="space-between">
            <span>{{ getStaffLevelByType("am") || "-" }}</span>
            <nb-icon
              icon="info-outline"
              [nbTooltip]="getTooltipInfo('am')"
            ></nb-icon>
          </div>
        </div>
        <div
          class="rota-summary-table__column rota-summary-table__column--with-label"
          [ngClass]="{
            over:
              getStaffLevelByType('pm') &&
              !getStaffLevelByType('pm').includes('-'),
            under:
              getStaffLevelByType('pm') &&
              getStaffLevelByType('pm').includes('-')
          }"
        >
          <div class="shift-type-label">PM</div>
          <div class="space-between">
            <span>{{ getStaffLevelByType("pm") || "-" }}</span>
            <nb-icon
              icon="info-outline"
              [nbTooltip]="getTooltipInfo('pm')"
            ></nb-icon>
          </div>
        </div>
        <div
          class="rota-summary-table__column rota-summary-table__column--with-label"
          [ngClass]="{
            over:
              getStaffLevelByType('night') &&
              !getStaffLevelByType('night').includes('-'),
            under:
              getStaffLevelByType('night') &&
              getStaffLevelByType('night').includes('-')
          }"
        >
          <div class="shift-type-label">Night</div>
          <div class="space-between">
            <span>{{ getStaffLevelByType("night") || "-" }}</span>
            <nb-icon
              icon="info-outline"
              [nbTooltip]="getTooltipInfo('night')"
            ></nb-icon>
          </div>
        </div>
      </div>
      <div class="rota-summary-table__row">
        <div
          class="rota-summary-table__column rota-summary-table__heading mark search-box"
        >
          <nb-icon icon="search-outline"></nb-icon>
          <input
            type="text"
            placeholder="Search..."
            [(ngModel)]="searchValue"
            (ngModelChange)="search($event)"
          />
        </div>
        <div
          class="rota-summary-table__column rota-summary-table__heading rota-summary-table__column--full roles-switcher-wrapper"
        >
          {{ getTypeName() }}
          <div class="roles-switcher">
            <nb-icon
              icon="arrow-ios-upward-outline"
              [ngClass]="{ disabled: selectedRoleIdIndex === 0 }"
              (click)="selectedRoleIdIndex && changeRoleFromArrow('up')"
            ></nb-icon>
            <nb-icon
              icon="arrow-ios-downward-outline"
              [ngClass]="{ disabled: isLastRoleSelected }"
              (click)="!isLastRoleSelected && changeRoleFromArrow('down')"
            ></nb-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="rota-summary-table__schedule">
      <div class="rota-summary-table__scrollable-persons thin-echo-scrollbar">
        <div class="spinner" *ngIf="!isLoaded">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <ng-container
          *ngFor="let employee of availableListFiltered; trackBy: trackByFn"
        >
          <div
            *ngIf="!employee.statusId; else outOfWork"
            class="rota-summary-table__person-wrapper"
            [draggable]="true"
            [dragData]="employee"
            (onDragStart)="onAddShiftDragStart(employee)"
            (onDragEnd)="onAddShiftDragEnd()"
            [nbTooltip]="
              employee.employeeFullName + ': ' + employee.employeePosition
            "
          >
            <div class="rota-summary-table__person">
              <div class="info">
                <img
                  [src]="employee.employeeAvatarUid | avatar | async"
                  class="avatar"
                  [alt]="employee.employeeFullName"
                  (click)="
                    toggleOverlayForEmployee(
                      employee.employeeId,
                      employee.employeeFullName
                    )
                  "
                  [nbPopover]="popover"
                  nbPopoverPlacement="right"
                />
                <div class="name">
                  <span class="first-name">{{
                    employee.employeeFullName
                  }}</span>
                  <span class="second-info" *ngIf="employee.employeePosition">{{
                    employee.employeePosition
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <ng-template #outOfWork>
            <div class="rota-summary-table__person-wrapper">
              <div
                class="rota-summary-table__person rota-summary-table__person--out-of-work"
              >
                <div class="info">
                  <img
                    [src]="employee.employeeAvatarUid | avatar | async"
                    class="avatar"
                    [alt]="employee.employeeFullName"
                    (click)="
                      toggleOverlayForEmployee(
                        employee.employeeId,
                        employee.employeeFullName
                      )
                    "
                    [nbPopover]="popover"
                    nbPopoverPlacement="right"
                  />
                  <div class="name">
                    <span class="first-name">{{
                      employee.employeeFullName
                    }}</span>
                    <span
                      class="second-info out-of-work"
                      *ngIf="employee.eventType"
                      >{{ employee.eventType }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <div class="rota-summary-table__separator"></div>

        <div
          class="rota-summary-table__person-wrapper"
          *ngFor="let employee of otherListFiltered; trackBy: trackByFn"
          [nbTooltip]="
            employee.employeeFullName + ': ' + employee.employeePosition
          "
          draggable="true"
          [dragData]="employee"
          (onDragStart)="onAddShiftDragStart(employee)"
          (onDragEnd)="onAddShiftDragEnd()"
        >
          <div
            class="rota-summary-table__person rota-summary-table__person--disabled"
          >
            <div class="info">
              <img
                [src]="employee.employeeAvatarUid | avatar | async"
                class="avatar"
                [alt]="employee.employeeFullName"
                (click)="
                  toggleOverlayForEmployee(
                    employee.employeeId,
                    employee.employeeFullName
                  )
                "
                [nbPopover]="popover"
                nbPopoverPlacement="right"
              />
              <div class="name">
                <span class="first-name">{{ employee.employeeFullName }}</span>
                <span class="second-info" *ngIf="employee.employeePosition">{{
                  employee.employeePosition
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="rota-summary-table__timeline"
        id="day-view-wrapper"
        #timelineContainer
        (dblclick)="addShift()"
      >
        <div class="rota-summary-table__hours-marker">
          <div class="rota-summary-table__hours-marker-label">4:00 AM</div>
        </div>
        <div class="rota-summary-table__hours-marker">
          <div class="rota-summary-table__hours-marker-label">8:00 AM</div>
        </div>
        <div class="rota-summary-table__hours-marker">
          <div class="rota-summary-table__hours-marker-label">12:00 PM</div>
        </div>
        <div class="rota-summary-table__hours-marker">
          <div class="rota-summary-table__hours-marker-label">4:00 PM</div>
        </div>
        <div class="rota-summary-table__hours-marker">
          <div class="rota-summary-table__hours-marker-label">8:00 PM</div>
        </div>

        <div class="spinner" *ngIf="!isLoaded">
          <mat-spinner diameter="30"></mat-spinner>
        </div>

        <!-- SHIFTS LIST -->
        <div class="shift-list thin-echo-scrollbar-hidden" #scrollContainer>
          <div class="shifts">
            <div class="rota-summary-table__next-role-marker">
              <span class="text">{{ currentRoleName }}</span>
            </div>
            <div
              class="rota-summary-table__person-wrapper"
              *ngFor="
                let day of previousDay;
                let i = index;
                trackBy: trackByPreviousDay
              "
              [style.width.px]="
                day.employeeStatus === 'Confirmed'
                  ? day.shiftConfirmedDuration * minuteInPx
                  : day.shiftScheduleDuration * minuteInPx
              "
            >
              <div
                class="rota-summary-table__person rota-summary-table__person--previous-day"
                [nbTooltip]="getShiftTooltip(day) + ' - From previous day'"
              >
                <div class="name">
                  <span class="first-name">{{ day.employeeFullName }}</span>
                </div>
              </div>
            </div>
            <ng-container
              *ngFor="
                let shift of shifts_list;
                let i = index;
                trackBy: trackByFn
              "
            >
              <rota-summary-table-shift
                [shift]="shift"
                [shiftWidth]="calculateShiftWidht(shift)"
                [shiftOffsetLeft]="calculateShiftOffset(shift)"
                [type]="type"
                [isSelected]="checkIfSelectedShift(shift)"
                [isInResize]="isInResize(shift)"
                [ctrlPressed]="ctrlPressed"
                (dragStart)="onDragStart($event)"
                (dragEnd)="onDragEnd()"
                (openEmployee360)="
                  openEmployee360($event.elementId, $event.elementFullName)
                "
                (selectedForResizing)="shiftSelectForResize($event)"
                (resizeStart)="
                  startResize($event.event, $event.shift, $event.direction)
                "
                (shiftSelected)="
                  toggleShiftSelection($event.checked, $event.shift)
                "
                (shiftEdit)="editShift($event, $event.employeePositionId)"
                (acceptSelected)="acceptSelected()"
                (denySelected)="denySelected()"
                (clearSelected)="clearSelected()"
              ></rota-summary-table-shift>
            </ng-container>
            <ng-container
              *ngFor="let shift of empty_shifts_list; trackBy: trackByFn"
            >
              <!-- EMPTY SHIFTS -->
              <div
                droppable
                (onDrop)="onDropOnEmptyShift($event, shift)"
                (click)="editShift(shift, roleId, true)"
                class="rota-summary-table__person-wrapper"
                [style.width.px]="calculateEmptyShiftWidth(shift)"
                [style.marginLeft.px]="calculateEmptyShiftOffset(shift)"
                [nbTooltip]="
                  getHoursForDisplay(shift) + ', ' + shift.employeeFullName
                "
              >
                <div
                  class="rota-summary-table__person shift rota-summary-table__person--disabled editable"
                  [ngClass]="{
                    'rota-summary-table__person--confirmed': isConfirmed(shift),
                    'rota-summary-table__person--selected':
                      checkIfSelectedShift(shift),
                    'rota-summary-table__person--resize': isInResize(shift)
                  }"
                  #shifts
                >
                  <div class="info">
                    <div class="name">
                      <span class="second-info">{{
                        getHoursForDisplayEmpty(shift)
                      }}</span>
                      <span class="first-name">{{
                        shift.employeeFullName
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="filler" [style.height.px]="fillerHeight"></div>
          </div>
          <div
            #nextRoleMarker
            *ngIf="this.roles.length > 1"
            class="rota-summary-table__next-role-marker"
          >
            <span class="text">{{ getLastRoleName }}</span>
          </div>
          <div class="shifts" *ngIf="this.roles.length > 1">
            <ng-container
              *ngFor="
                let shift of next_role_shifts_list;
                let i = index;
                trackBy: trackByFn
              "
            >
              <rota-summary-table-shift
                *ngIf="shift.employeeId && shift.shiftScheduleDuration !== 0"
                [shift]="shift"
                [shiftWidth]="calculateShiftWidht(shift)"
                [shiftOffsetLeft]="calculateShiftOffset(shift)"
                [type]="type"
                [isSelected]="checkIfSelectedShift(shift)"
                [isInResize]="isInResize(shift)"
                [ctrlPressed]="ctrlPressed"
                (dragStart)="onDragStart($event)"
                (dragEnd)="onDragEnd()"
                (openEmployee360)="
                  openEmployee360($event.elementId, $event.elementFullName)
                "
                (selectedForResizing)="shiftSelectForResize($event)"
                (resizeStart)="
                  startResize($event.event, $event.shift, $event.direction)
                "
                (shiftSelected)="
                  toggleShiftSelection($event.checked, $event.shift)
                "
                (shiftEdit)="editShift($event, $event.employeePositionId)"
              ></rota-summary-table-shift>

              <!-- EMPTY SHIFTS NEXT DAY -->
            </ng-container>
            <ng-container
              *ngFor="
                let shift of next_role_empty_shifts_list;
                trackBy: trackByFn
              "
            >
              <div
                droppable
                (onDrop)="
                  onDropOnEmptyShift(
                    $event,
                    shift,
                    getRoleIdByIndex(incrementIndex())
                  )
                "
                (click)="
                  editShift(shift, getRoleIdByIndex(incrementIndex()), true)
                "
                class="rota-summary-table__person-wrapper"
                [style.width.px]="calculateEmptyShiftWidth(shift)"
                [style.marginLeft.px]="calculateEmptyShiftOffset(shift)"
                [nbTooltip]="
                  getHoursForDisplay(shift) + ', ' + shift.employeeFullName
                "
              >
                <div
                  class="rota-summary-table__person shift rota-summary-table__person--disabled editable"
                  [ngClass]="{
                    'rota-summary-table__person--confirmed': isConfirmed(shift),
                    'rota-summary-table__person--selected':
                      checkIfSelectedShift(shift),
                    'rota-summary-table__person--resize': isInResize(shift)
                  }"
                  #shifts
                >
                  <div class="info">
                    <div class="name">
                      <span class="second-info">{{
                        getHoursForDisplayEmpty(shift)
                      }}</span>
                      <span class="first-name">{{
                        shift.employeeFullName
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="notes">
          Notes:
          <textarea
            [(ngModel)]="notes"
            (focusout)="saveNotes()"
            (keydown.enter)="saveNotes()"
            class="thin-echo-scrollbar-5"
          ></textarea>
          <div class="author">{{ notesDate }} - {{ notesUser }}</div>
        </div>
      </div>
    </div>

    <div
      class="clear-assignment small cdk-clear-assignment"
      [style.display]="draggableItem && draggableItem?.rotaId ? 'flex' : 'none'"
      droppable
      (onDrop)="deleteShift(draggableItem, dayDate)"
    >
      <div class="clear-assignment__content">
        <div class="text g_red">
          Move here to clear the assignment<br />
          <nb-icon icon="backspace-outline"></nb-icon>
          Clear
        </div>
      </div>
    </div>

    <div
      class="clear-assignment add-here cdk-clear-assignment"
      [style.display]="
        isLoaded && (shifts_list.length === 0 || addShiftDragItem)
          ? 'flex'
          : 'none'
      "
      (click)="addShift()"
    >
      <div class="clear-assignment__content">
        <div
          class="add-shift-field"
          droppable
          (onDrop)="addNewShift('00:00', '04:00', $event)"
        ></div>
        <div
          class="add-shift-field"
          droppable
          (onDrop)="addNewShift('04:00', '08:00', $event)"
        ></div>
        <div
          class="add-shift-field"
          droppable
          (onDrop)="addNewShift('08:00', '12:00', $event)"
        ></div>
        <div
          class="add-shift-field"
          droppable
          (onDrop)="addNewShift('12:00', '16:00', $event)"
        ></div>
        <div
          class="add-shift-field"
          droppable
          (onDrop)="addNewShift('16:00', '20:00', $event)"
        ></div>

        <div
          class="add-shift-field"
          droppable
          (onDrop)="addNewShift('20:00', '00:00', $event)"
        ></div>

        <div class="text">
          <nb-icon icon="plus-outline"></nb-icon>
          To add a shift, click here or drop a name from the left into this box
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #popover>
  <div
    class="open-employee"
    (click)="openEmployee360(overlayEmployeeId, overlayEmployeeFullName)"
  >
    Open Employee360
  </div>
</ng-template>
